import React from 'react';
import Layout from '../Layout';  // Adjust the path as per your file structure

const RecognizeProduct = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">Baton Recognize: Intelligent Facial Recognition for Axis Devices</h1>

        <section className="general-section">
          <h2>Transforming Security and Interaction</h2>
          <p>Baton Recognize brings state-of-the-art facial recognition capabilities to your Axis device network, enabling enhanced security measures, personalized interactions, and data-driven insights. By integrating seamlessly with Axis cameras, Baton Recognize provides real-time identification and response, setting a new standard for automated surveillance and interaction.</p>
          <div className="start-with-baton-links">
            <a href="mailto:Ryan@batonacap.com" className="btn btn-primary">Learn More About Baton Recognize</a>
            <a href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/BatonAcap.com%20-%20Info%20Sheet.pdf?alt=media&token=d5d82e5a-6c2c-4451-b799-d1723f166741" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Download Info Sheet</a>
          </div>
        </section>

        <section className="general-section">
          <h2>Core Features</h2>
          <ul>
            <li><strong>Real-Time Identification:</strong> Instantly recognize individuals from a database of known faces, and receive alerts or trigger actions based on identifications.</li>
            <li><strong>Enhanced Security:</strong> Utilize facial recognition to augment your security protocols, allowing for automated access control and intruder detection.</li>
            <li><strong>Personalized Experiences:</strong> Tailor interactions in retail, hospitality, or other customer-facing settings based on the identification of individuals.</li>
            <li><strong>Data Analytics:</strong> Gather valuable insights on foot traffic, visitor demographics, and more, enabling data-driven decision-making.</li>
          </ul>
        </section>

        <section className="general-section">
          <h2>Applications Across Industries</h2>
          <p>From enhancing retail experiences to securing critical infrastructure, Baton Recognize's applications are as varied as they are impactful. Whether you're looking to streamline operations, bolster security, or create personalized experiences, Baton Recognize is your solution.</p>
        </section>

        <section className="general-section">
          <h2>Start with Baton Recognize</h2>
          <p>Ready to harness the power of facial recognition in your Axis device network? Contact us to discover how Baton Recognize can be integrated into your environment or to learn more about its capabilities and potential applications.</p>
          <div className="start-with-baton-links">
            <a href="mailto:Ryan@batonacap.com" className="btn btn-primary">Learn More About Baton Recognize</a>
            <a href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/BatonAcap.com%20-%20Info%20Sheet.pdf?alt=media&token=d5d82e5a-6c2c-4451-b799-d1723f166741" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Download Info Sheet</a>
          </div>
        </section>

      </div>
    </Layout>
  );
};

export default RecognizeProduct;