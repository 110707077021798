import ReactGA from 'react-ga';

const initializeAnalytics = () => {
  ReactGA.initialize('G-LSKCEJVEJS');
};

const trackPageView = (page) => {
  ReactGA.pageview(page);
};

const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export { initializeAnalytics, trackPageView, trackEvent };

