// In src/components/Home.js

import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">Welcome to Baton – Enhancing Axis Network Solutions</h1>

        <section className="intro-section">
          <p>Baton harnesses the power of Axis network devices, transforming them into a cohesive, intelligent security network. With Baton React for advanced device orchestration and Baton Recognize for innovative facial recognition, discover new possibilities in security and operational efficiency.</p>
        </section>

        <section className="product-highlights">
          <div className="product">
            <h2>Baton React – Advanced Device Orchestration</h2>
            <p>Orchestrate your Axis devices with Baton React, enabling them to act and react in harmony. Automate responses, enhance security, and streamline operations with our intuitive platform designed for seamless integration and maximum efficiency.</p>
            <Link to="/products/react" className="btn btn-primary">Learn More</Link>
          </div>

          <div className="product">
            <h2>SAFR Cloud – Powerful Facial Recognition Platform</h2>
            <p>Harness the power of facial recognition with SAFR Cloud, a cutting-edge platform that delivers accurate, real-time identification and analysis. Enhance security, optimize operations, and deliver personalized experiences across various industries.</p>
            <Link to="/products/safr-cloud" className="btn btn-primary">Explore SAFR Cloud</Link>
          </div>

          <div className="product">
            <h2>Baton Recognize – Innovative Facial Recognition</h2>
            <p>Empower your Axis devices with Baton Recognize, integrating cutting-edge facial recognition technology to enhance security and create personalized user experiences. Transform your network into a smart, responsive ecosystem with our advanced AI solutions.</p>
            <Link to="/products/recognize" className="btn btn-primary">Discover More</Link>
            <a href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/BatonAcap.com%20-%20Info%20Sheet.pdf?alt=media&token=d5d82e5a-6c2c-4451-b799-d1723f166741" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Download Info Sheet</a>
          </div>
        </section>

        <section className="applications">
          <h2>Real-World Applications of Baton Solutions</h2>
          <p>Whether securing a campus, enhancing retail experiences, or creating smart, connected environments, Baton's solutions are versatile and impactful. Explore how our technologies are applied across various industries to improve safety, efficiency, and user experience.</p>
          <Link to="/applications" className="btn btn-primary">Explore Applications</Link>
        </section>

        <section className="get-involved">
          <h2>Join the Baton Community – Innovate with Us</h2>
          <p>Become a part of the Baton journey. Engage with us to explore how our solutions can transform your Axis device network. Reach out for more information, become a design partner, or join our community of innovators dedicated to advancing security and network solutions.</p>
          <div className="get-involved-links">
            <Link to="/contact" className="btn btn-primary">Get Involved</Link>
            <a href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/BatonAcap.com%20-%20Info%20Sheet.pdf?alt=media&token=d5d82e5a-6c2c-4451-b799-d1723f166741" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Download Info Sheet</a>
          </div>
        </section>

      </div>
    </Layout>
  );
};

export default Home;
