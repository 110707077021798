import React from 'react';
import Layout from './Layout';
import { trackEvent } from '../analytics';

const handleDownloadClick = (version, soc) => {
  trackEvent('Download', 'Click', `Baton Recognize ${version} (${soc})`);
};

const Downloads = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">Baton Recognize Downloads</h1>
        
        <section className="general-section">
          <h2>Firmware 11.11+</h2>
          <p>Download the latest version of Baton Recognize for Axis cameras running firmware 11.11 or higher.</p>
          
          <div className="download-card">
            <h3>Baton Recognize 1.2.652 (ARTPEC-8)</h3>
            <p>Version: 1.2.652</p>
            <p>SoC: ARTPEC-8 (aarch64) - <a href="https://www.axis.com/solutions/system-on-chip" target="_blank" rel="noopener noreferrer">Learn more</a></p>
            <p>Release Date: July 29th, 2024</p>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/signed_Baton_-_Recognize_1_2_652_aarch64.eap?alt=media&token=d4d622c2-bd6e-4ca7-9ab3-984f932796b4"
              download="Baton_Recognize_1_2_652_aarch64.eap"
              className="btn btn-primary"
              onClick={() => handleDownloadClick('1.2.652', 'ARTPEC-8')}
            >
              Download
            </a>
          </div>
          
          <div className="download-card">
            <h3>Baton Recognize 1.2.652 (ARTPEC-7)</h3>
            <p>Version: 1.2.652</p>
            <p>SoC: ARTPEC-7 (armv7hf) - <a href="https://www.axis.com/solutions/system-on-chip" target="_blank" rel="noopener noreferrer">Learn more</a></p>
            <p>Release Date: July 29th, 2024</p>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/signed_Baton_-_Recognize_1_2_652_armv7hf.eap?alt=media&token=1f5a58a0-7daf-481b-8956-33bf02b666d9"
              download="signed_Baton_Recognize_1_2_652_armv7hf.eap"
              className="btn btn-primary"
              onClick={() => handleDownloadClick('1.2.652', 'ARTPEC-7')}
            >
              Download
            </a>
          </div>
        </section>
        
        <section className="general-section">
          <h2>Firmware 11.9-11.10</h2>
          <p>Download the latest version of Baton Recognize for Axis cameras running firmware 11.9 or 11.10.</p>
          
          <div className="download-card">
            <h3>Baton Recognize 1.2.618 (ARTPEC-8)</h3>
            <p>Version: 1.2.618</p>
            <p>SoC: ARTPEC-8 (aarch64) - <a href="https://www.axis.com/solutions/system-on-chip" target="_blank" rel="noopener noreferrer">Learn more</a></p>
            <p>Release Date: July 3rd, 2024</p>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/signed_Baton_-_Recognize_1_2_618_aarch64.eap?alt=media&token=24db9a7c-3c81-44f5-a68c-85bdf2f012fb"
              download="signed_Baton_Recognize_1_2_618_aarch64.eap"
              className="btn btn-primary"
              onClick={() => handleDownloadClick('1.2.618', 'ARTPEC-8')}
            >
              Download
            </a>
          </div>
          
          <div className="download-card">
            <h3>Baton Recognize 1.2.618 (ARTPEC-7)</h3>
            <p>Version: 1.2.618</p>
            <p>SoC: ARTPEC-7 (armv7hf) - <a href="https://www.axis.com/solutions/system-on-chip" target="_blank" rel="noopener noreferrer">Learn more</a></p>
            <p>Release Date: July 3rd, 2024</p>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/baton-5397b.appspot.com/o/signed_Baton_-_Recognize_1_2_618_armv7hf.eap?alt=media&token=a0888259-6cd1-4494-b391-5a0a001b931a"
              download="Baton_Recognize_1_2_618_armv7hf.eap"
              className="btn btn-primary"
              onClick={() => handleDownloadClick('1.2.618', 'ARTPEC-7')}
            >
              Download
            </a>
          </div>
        </section>
        
        <section className="general-section">
          <h2>Installation Guide</h2>
          <p>For detailed instructions on installing and configuring Baton Recognize, please refer to our <a href="/install-guide">Installation Guide</a>.</p>
        </section>
        
        <section className="general-section">
          <h2>Support</h2>
          <p>If you encounter any issues or have questions, please contact our support team at <a href="mailto:ryan@batonacap.com">ryan@batonacap.com</a>.</p>
        </section>
      </div>
    </Layout>
  );
};

export default Downloads;
