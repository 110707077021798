import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { trackPageView } from './analytics';

import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Downloads from './components/Downloads';
import Licensing from './components/Licensing';
import ReactProduct from './components/products/React';
import RecognizeProduct from './components/products/Recognize';
import InstallGuide from './components/InstallGuide';
import Applications from './components/Applications';
import SAFRCloudProduct from './components/products/SAFRCloud';


function App() {
  React.useEffect(() => {
    trackPageView(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} />
        <Route path="/licensing" element={<Licensing />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products/react" element={<ReactProduct />} />
        <Route path="/products/recognize" element={<RecognizeProduct />} />
        <Route path="/install-guide" element={<InstallGuide />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/products/safr-cloud" element={<SAFRCloudProduct />} />
      </Routes>
    </Router>
  );
}

export default App;