import React from 'react';
import Layout from './Layout';

const Licensing = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">Licensing Baton</h1>
        
        <section className="general-section">
          <h2>Unlock Full Access</h2>
          <p>Learn about the licensing process and how it opens the door to a new realm of possibilities with your Axis devices.</p>
        </section>

        <section className="general-section">
          <h2>Sales and Pricing Inquiries</h2>
          <p>For detailed information on licensing, pricing, and to discuss how Baton can fit into your ecosystem, please contact:</p>
          <div className="contact-info">
            <p>
              <strong>Chris Smith</strong><br />
              SAFR<br />
              Sales Director
            </p>
            <p>
              <a href="tel:484-888-9890">484-888-9890</a><br />
              <a href="mailto:csmith@realnetworks.com">csmith@realnetworks.com</a>
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Licensing;
