import React from 'react';
import Layout from './Layout';

const Contact = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">Get in Touch</h1>
        
        <section className="general-section">
          <h2>SAFR Cloud Integration</h2>
          <p>For inquiries related to SAFR Cloud integration with your Axis devices through Baton, please contact Chris Smith, Director of Product Management at RealNetworks.</p>
          <a href="mailto:chsmith@realnetworks.com" className="btn btn-primary">Email Chris Smith</a>
        </section>

        <section className="general-section">
          <p>Have questions or want to learn more about Baton? Ryan is available to provide insights and discuss how Baton can enhance your Axis device ecosystem.</p>
          <a href="mailto:ryan@batonacap.com" className="btn btn-primary">Email Ryan</a>
        </section>

      </div>
    </Layout>
  );
};

export default Contact;
