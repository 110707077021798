// In src/components/Applications.js
import React from 'react';
import Layout from './Layout';

const Applications = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">Applications of Facial Recognition Technology</h1>

        <section className="application-section">
          <h2>Access Control and Authentication</h2>
          <p>Facial recognition technology can revolutionize access control systems by providing a secure and convenient way to authenticate individuals. By integrating Baton Recognize with existing access control infrastructure, organizations can enhance security, streamline entry processes, and eliminate the need for traditional access cards or keys.</p>
        </section>

        <section className="application-section">
          <h2>Security and Surveillance</h2>
          <p>Baton Recognize empowers security teams with real-time facial recognition capabilities. By identifying and alerting security personnel to the presence of persons of interest, potential threats can be proactively addressed. Facial recognition can also aid in post-event investigations by quickly searching through surveillance footage to identify suspects or persons of interest.</p>
        </section>

        <section className="application-section">
          <h2>Business Intelligence and Customer Experience</h2>
          <p>Facial recognition technology opens up new possibilities for businesses to gather valuable insights and enhance customer experiences. By analyzing customer demographics, sentiment, and behavior, retailers can optimize store layouts, personalize marketing campaigns, and improve customer service. Facial recognition can also enable VIP recognition, allowing businesses to provide tailored experiences to high-value customers.</p>
        </section>

        <section className="application-section">
          <h2>Attendance Tracking and Time Management</h2>
          <p>Baton Recognize can streamline attendance tracking and time management processes in various settings, such as educational institutions, workplaces, and events. By automatically recognizing individuals as they enter or exit a facility, accurate attendance records can be maintained effortlessly. This eliminates the need for manual check-ins and reduces the risk of errors or fraud.</p>
        </section>

        <section className="application-section">
          <h2>Visitor Management and Hospitality</h2>
          <p>Facial recognition technology can elevate visitor management and hospitality experiences. By recognizing and greeting guests by name, hotels and other hospitality venues can provide a personalized and welcoming atmosphere. Facial recognition can also expedite check-in processes, enable keyless room entry, and enhance overall security within the premises.</p>
        </section>
      </div>
    </Layout>
  );
};

export default Applications;
