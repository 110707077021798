import React from 'react';
import Layout from '../Layout';  // Adjust the path as per your file structure

const ReactProduct = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">Baton React: Empower Your Axis Devices</h1>

        <section className="general-section">
          <h2>Unleash the Power of Device Orchestration</h2>
          <p>Baton React is a revolutionary platform that transforms your Axis devices into an interconnected, intelligent network. By enabling devices to communicate and react to each other's triggers, Baton React creates a symphony of coordinated actions, enhancing security, efficiency, and responsiveness across your ecosystem.</p>
        </section>

        <section className="general-section">
          <h2>Key Features</h2>
          <ul>
            <li><strong>Dynamic Device Interaction:</strong> Set up complex interactions where one device's action triggers responses across multiple devices.</li>
            <li><strong>Event-Driven Orchestration:</strong> Design scenarios where specific events initiate predefined sequences, optimizing operational efficiency and responsiveness.</li>
            <li><strong>Customizable Triggers and Actions:</strong> Define your triggers and actions, tailoring the behavior of your Axis devices to your specific needs.</li>
            <li><strong>Intuitive Interface:</strong> Configure and manage your device network with an easy-to-use interface that simplifies complex configurations.</li>
          </ul>
        </section>

        <section className="general-section">
          <h2>Real-World Applications</h2>
          <p>Baton React's versatility allows it to be utilized across various industries and scenarios. Here are just a few examples of how it can transform your environment:</p>
          <ul>
            <li><strong>Enhanced Security Protocols:</strong> Link cameras, alarms, and door controls to react instantly to security breaches, streamlining your response to threats.</li>
            <li><strong>Operational Efficiency:</strong> Automate responses to environmental sensors, reducing the need for manual intervention and streamlining daily operations.</li>
            <li><strong>Customer Experience:</strong> Use Baton React in retail or hospitality settings to create ambient environments that respond to customer presence or activities.</li>
          </ul>
        </section>

        <section className="general-section">
          <h2>Get Started with Baton React</h2>
          <p>Embark on a journey to redefine the capabilities of your Axis device network. Contact us to explore how Baton React can be tailored to your needs, or join our design partner program to shape the future of device orchestration.</p>
          <a href="mailto:Ryan@batonacap.com" className="btn btn-primary">Contact Us to Learn More</a>
        </section>
      </div>
    </Layout>
  );
};

export default ReactProduct;