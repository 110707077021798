import React from 'react';
import Layout from './Layout';
import '../InstallGuide.css';

function InstallGuide() {
  return (
    <Layout>
      <div className="install-guide">
        <h1>Baton Recognize - Install Guide</h1>
        {/* Add a link to the OpenPath Configuration section */}
        <p>If you're using OpenPath integration, please refer to the <a href="#openpath-config">OpenPath Configuration</a> section.</p>

        <h2>Step 1: Prepare for installation</h2>
        <ul>
          <li>Access your Camera's Web Settings Interface</li>
          <li>Log in to your camera's settings page.</li>
          <li>Navigate to the 'Apps' menu on the left-hand side.</li>
          <li>
            <strong>Download the correct package for your camera's SoC from the <a href="/downloads">Downloads</a> page.</strong>
          </li>
          <li>Find your license key</li>
        </ul>
        <div className="callout">
          <p>
            <strong>Important:</strong> Before proceeding with the installation, ensure that your camera is running firmware version 11.11 or higher. To update the firmware, visit <a href="https://www.axis.com/support/device-software" target="_blank" rel="noopener noreferrer">Axis Device Software</a> and search for your camera model.
          </p>
        </div>
        <h2>Step 2: Install the Application</h2>
        <ul>
          <li><strong>Move the slider for "Allow root-privileged apps" to On</strong></li>
          <li>Click on the plus sign (+) next to 'Add App'.</li>
          <li>In the new window, select the option to upload the .EAP file you downloaded in Step 1.</li>
          <li>Click 'Submit' and wait for the installation process to complete.</li>
        </ul>
        <h2>Step 3: Activate the License</h2>
        <ul>
          <li>Find Baton Recognize in the list of apps.</li>
          <li>Click on the three vertical dots on the right-hand side of Baton Recognize.</li>
          <li>Select 'Activate the License by Key'.</li>
          <li>Paste the licensing key provided to you and activate the software.</li>
        </ul>
        <h2>Step 4: Configure App Settings</h2>
        <div className="new-version-callout">
          <p><strong>New in version 1.2.652 (July 29th):</strong> Advanced processing settings have been added to fine-tune detection and identification behavior.</p>
        </div>
        <ul>
          <li>Return to the three dots next to Baton Recognize and select 'App Settings'.</li>
          <li>Configure the following settings (*-required):</li>
          <ul>
            <li>*SAFR Source: Enter a description of the camera you are installing this on (e.g. Northwest Doorway).</li>
            <li>*SAFR Site: Enter the site used in SAFR to identify the location of this install (e.g. main)</li>
            <li>*SAFR User: Enter the assigned user for SAFR Cloud</li>
            <li>*SAFR Password: Enter your SAFR Cloud password</li>
            <li>Overlay: Enable if you wish to view Baton-Recognize information as an overlay on the default streams within your Axis Camera Interface.</li>
            <li>SAFR Detect Age: Enable if you wish for SAFR to estimate the person's age when identifying them.</li>
            <li>SAFR Detect Gender: Enable this to allow SAFR to detect and record the gender of individuals identified by the system.</li>
            <li>SAFR Detect Mask: Turn this on if you want the system to detect whether individuals are wearing masks.</li>
            <li>SAFR Detect Occlusion: Enable this setting to allow the system to detect if an individual's face is partially obscured.</li>
            <li>SAFR Detect Sentiment: Activate this feature to analyze and detect any background or environmental factors in the video feed.</li>
            <li>SafrHumanNoNameThreshold: Set the interval (in milliseconds) for processing unidentified human detections. Default: 700</li>
            <li>SafrFaceWithNameThreshold: Set the interval (in milliseconds) for processing already identified faces. Default: 1500</li>
            <li>SafrFaceNoNameThreshold: Set the interval (in milliseconds) for processing unidentified faces. Default: 500</li>
            <li>SafrBurstPeriod: Set the duration (in milliseconds) for initial rapid processing of new detections. Default: 500</li>
          </ul>
          <li>Click 'Save' to finalize your settings.</li>
          <li>Enable Baton Recognize via the status slider until it turns orange.</li>
        </ul>
        <div className="advanced-settings-info">
          <h3>Advanced Processing Settings</h3>
          <p>The following settings allow you to fine-tune the detection and identification behavior of Baton Recognize:</p>
          <ul>
            <li><strong>SafrHumanNoNameThreshold:</strong> Controls how often the app attempts to process unidentified humans. A lower value increases processing frequency but may use more system resources.</li>
            <li><strong>SafrFaceWithNameThreshold:</strong> Determines how often already identified faces are processed. A higher value reduces processing frequency for known individuals, potentially saving resources.</li>
            <li><strong>SafrFaceNoNameThreshold:</strong> Sets the processing interval for unidentified faces. A lower value allows for more frequent face identification attempts.</li>
            <li><strong>SafrBurstPeriod:</strong> Defines an initial period of rapid processing for new detections, allowing for quick initial identification attempts.</li>
          </ul>
          <p>Adjust these values based on your specific needs and system capabilities to optimize the balance between identification accuracy, system resource usage, and responsiveness.</p>
        </div>
        <h2>Confirm operations by</h2>
        <ul>
          <li>Logging onto the SAFR cloud and viewing events via web or app</li>
          <li>Checking the app log from the 3 vertical dots menu</li>
          <li>Watching the live stream for identification by overlay</li>
          <li>Logging onto the SAFR cloud and viewing events via web or app</li>
        </ul>
        {/* Add the new OpenPath Configuration section */}
        <h2 id="openpath-config">OpenPath Configuration</h2>
        <p>If you're using OpenPath integration, follow these additional steps:</p>
        <ol>
          <li>Go to the app settings as described in Step 4 above.</li>
          <li>Enable "OpenPath Integration".</li>
          <li>Enter your "OpenPath API Key".</li>
          <li>Assign the camera an "OpenPath Door Entry ID".</li>
        </ol>
        <p>To update the person profile on SAFR for anyone you would like to use this feature:</p>
        <ul>
          <li>Ensure you have an email address assigned to their profile.</li>
          <li>Verify that the email address matches the one used to identify them in the OpenPath rules engine.</li>
        </ul>
      </div>
    </Layout>
  );
}

export default InstallGuide;
