import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class BatonNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    return (
      <Navbar color="light" light expand="md">
        <NavbarBrand to="/" tag={Link}>Baton</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink to="/" className="nav-link" activeClassName="active">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about" className="nav-link" activeClassName="active">About</NavLink>
            </NavItem>
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
              <DropdownToggle nav caret>
                Products
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavLink to="/products/safr-cloud" className="nav-link" activeClassName="active">SAFR Cloud</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink to="/products/react" className="nav-link" activeClassName="active">Baton React</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink to="/products/recognize" className="nav-link" activeClassName="active">Baton Recognize</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <NavItem>
              <NavLink to="/applications" className="nav-link" activeClassName="active">Applications</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/downloads" className="nav-link" activeClassName="active">Downloads</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/licensing" className="nav-link" activeClassName="active">Licensing</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/install-guide" className="nav-link" activeClassName="active">Install Guide</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/contact" className="nav-link" activeClassName="active">Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
};

export default BatonNavbar;
