import React from 'react';
import Layout from './Layout';

const About = () => {
  return (
    <Layout>
      <div className="page-container">
        <h1 className="page-heading">About Baton</h1>
        
        <section className="general-section">
          <h2>The Genesis of Innovation</h2>
          <p>Baton was born from a vision to transcend conventional device interaction within the Axis ecosystem. Founded by Ryan Wager, Baton represents a leap into the future of IoT connectivity and orchestration, addressing the need for integrated device control and response.</p>
        </section>

        <section className="general-section">
          <h2>Our Mission</h2>
          <p>Our mission is to enhance Axis technology, providing users with capabilities previously unimagined. We envision a world where devices not only communicate but collaborate, creating a cohesive and intelligent network.</p>
        </section>

        <section className="general-section">
          <h2>The Baton Experience</h2>
          <p>Baton is an exclusive offering in the IoT space, providing a unique platform for users to transform their Axis devices into a synchronized orchestra of functionality.</p>
        </section>

        <section className="general-section">
          <h2>Ryan Wager: The Maestro Behind Baton</h2>
          <p>Ryan's expertise in Embedded C and deep understanding of Axis devices have been pivotal in crafting Baton's innovative approach to device orchestration. His vision has brought Baton from concept to reality, offering a platform that's both robust and revolutionary.</p>
        </section>

        <section className="general-section">
          <h2>Innovation at the Core</h2>
          <p>Baton's use of MQTT as a command and control fabric is just the beginning. Its integration with advanced Axis features and the ability to interact with cutting-edge technologies like SAFR Cloud, ChatGPT, and ElevenLabs demonstrates Baton's commitment to innovation.</p>
        </section>

        <section className="general-section">
          <h2>Join the Exclusive Circle</h2>
          <p>Access to Baton is limited, offering a unique opportunity to those who share our passion for the cutting edge. By connecting with Ryan, you're not just accessing new technology; you're joining a movement at the forefront of IoT evolution.</p>
          <a href="mailto:Ryan@segistics.io" className="btn btn-primary">Connect with Ryan</a>
        </section>

        <section className="general-section">
          <h2>Envisioning the Future</h2>
          <p>With a focus on community-driven expansion and continuous innovation, Baton's journey is just beginning. We're committed to evolving with our users, ensuring that Baton remains at the forefront of IoT technology.</p>
        </section>
      </div>
    </Layout>
  );
};

export default About;