import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <span className="text-muted">© {year} Baton. All Rights Reserved.</span>
        <ul className="footer-nav">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/products/react">Baton React</Link></li>
          <li><Link to="/products/recognize">Baton Recognize</Link></li>
          <li><Link to="/applications">Applications</Link></li>
          <li><Link to="/downloads">Downloads</Link></li>
          <li><Link to="/licensing">Licensing</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;

